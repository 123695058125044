.link {
  color: #0f375f;
}

.list {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;
}

.listItem {
  display: block;
  position: relative;
  line-height: 24px;
  font-size: 1rem;
  margin: 20px 20px 50px 50px;

  &:before {
    display: block;
    position: absolute;
    left: -30px;
    content: counter(item) '  ';
    counter-increment: item;
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    color: white;
    width: 24px;
    height: 24px;
    background-color: #0f375f;
    border-radius: 50%;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    left: -18px;
    top: 37px;
    border-left: solid #bdbdbd 1px;
  }
  &:has(li) {
    margin-bottom: 30px;
    &:after {
      height: calc(100% - 20px);
    }
  }
  &:last-child {
    margin-bottom: 0;
    &:not(:has(li)) {
      &:after {
        height: 0;
      }
    }
  }
}

.listSubItem {
  display: block;
  position: relative;
  line-height: 24px;
  font-size: 0.875rem;
  margin: 16px 20px 16px 40px;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -26px;
    top: 7px;
    width: 10px;
    height: 10px;
    background-color: #0f375f;
    border-radius: 50%;
  }
  &:last-child {
    margin-bottom: 10px;
  }
}

.listSubItem-small {
  font-size: 0.75rem;
}

.listItem-small {
  font-size: 0.875rem;
}
