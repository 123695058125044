@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import 'https://fonts.googleapis.com/css2?family=Mulish&display=swap';
@import './CollaborativeEditor.module.scss';
@import './ProgressChart.module.scss';

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Mulish, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  overflow: auto !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.video {
  iframe {
    width: 100%;
    height: 100%;
  }
}

.carousel-next-prev-btn {
  height: auto;
  button {
    background: rgba(0, 0, 0, 0.12);
    &.dark-theme {
      background: rgba(255, 255, 255, 0.3);
    }
    &.Mui-disabled {
      background: rgba(0, 0, 0, 0.04);
      &.dark-theme {
        background: rgba(255, 255, 255, 0.03);
        color: #000;
      }
    }
  }
  &:hover {
    button {
      background: rgba(0, 0, 0, 0.12);
      &.dark-theme {
        background: rgba(255, 255, 255, 0.3);
      }
      &.Mui-disabled {
        opacity: 1;
        background: rgba(0, 0, 0, 0.04);
        &.dark-theme {
          background: rgba(255, 255, 255, 0.03);
          color: #000;
        }
      }
    }
  }
}
