.progress-chart {
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  rect {
    stroke: #fff;
  }

  line:last-of-type {
    display: none;
  }

  .axis path,
  .axis line {
    display: none;
  }

  .x.axis text {
    display: none;
  }

  .y.axis text {
    display: none;
  }

  .user-avatar {
    margin-right: 10px;
    vertical-align: middle;
  }

  .phase-text {
    font-weight: 600;
    font-size: 1rem;
  }

  .activity-status {
    fill: rgba(0, 0, 0, 0.38);
  }
}

.progress-chart-phases {
  background-color: #fff;

  text {
    font-weight: 600;
    font-size: 1rem;
  }

  .assignments-completed {
    cursor: pointer;
  }

  .assignments-completed-title {
    font-weight: 400;
    font-size: 0.75rem;
    fill: rgba(0, 0, 0, 0.6);
  }

  .assignments-completed-sort-icon {
    transform-box: fill-box;
    transform-origin: center;
    fill: rgba(0, 0, 0, 0.38);
  }
}

.progress-tooltip {
  // visibility: hidden;
  opacity: 0;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 20px 1px;
  border-left: 3px solid;
  overflow: visible;
}

.progress-tooltip::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid rgb(255, 255, 255);
  // bottom: -50%;
  left: 20px;
  margin-bottom: 15px;
}

.progress-tooltip.position-top::after {
  top: -7px;
  transform: rotate(180deg);
}

.progress-tooltip.position-right::after {
  right: 20px;
  left: unset;
}

.avatar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-family: Mulish, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fafafa;
  background-color: #bdbdbd;
  width: 100%;
  height: 100%;
}

[data-theme='dark'] {
  .avatar {
    color: #1e1f21;
    background-color: #757575;
  }
  .activity-status {
    fill: rgba(255, 255, 255, 0.5);
  }
  .progress-tooltip::after {
    border-top: 7px solid #383838;
  }
  .assignments-completed-title {
    fill: rgba(255, 255, 255, 0.56);
  }
  .assignments-completed-sort-icon {
    fill: rgba(255, 255, 255, 0.5);
  }
}
